import React, { Suspense, lazy } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import StartPage from './components/StartPage/StartPage'
import SupplierHome from './components/SupplierHome/SupplierHome'
import Results from './components/Results/Results'
import Cart from './components/Cart/Cart'
import SupplierRedirect from './components/SupplierRedirect/SupplierRedirect'
import AppRoute from './components/Layout/AppRoute'

const Terms = lazy(() => import('./components/Pages/Terms'))
const DataPrivacy = lazy(() => import('./components/Pages/DataPrivacy'))
const Imprint = lazy(() => import('./components/Pages/Imprint'))
const GetListed = lazy(() => import('./components/GetListed/GetListed'))
const MiceMomentsExpandedView = lazy(() =>
  import('./components/MiceMoments/MiceMomentsExpandedView')
)

const deliverables = [
  'hotel',
  'location',
  'event-gastronomy',
  'transfer',
  'personal-service',
  'catering',
  'event-facility',
  'convention-bureau'
]

const Routes = (props) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        {/* The components passed to these routes are already wrapped inside a layout */}
        {/* <ProtectedRoute
          exact
          path='/:userType/:objectID/admin/:tabs?'
          component={SupplierAdmin}
          isLoggedIn={props.isLoggedIn}
        /> */}
        {/* <ProtectedRoute
          exact
          path='/:userType/:objectID/admin/suppliers/:supplierID/edit/:deliverableType'
          component={SupplierEditHome}
          isLoggedIn={props.isLoggedIn}
        /> */}
        {/* <ProtectedRoute
          exact
          path='/:userType/:objectID/edit/:deliverableType'
          component={SupplierEditHome}
          isLoggedIn={props.isLoggedIn}
        /> */}

        {/* END The components passed to these routes are already wrapped inside a layout */}

        {/* TODO: login */}
        <Route
          exact
          path='/login'
          component={(props) => {
            window.location.href = `/login?return_url=${props.location.state.from.pathname}`
            return null
          }}
        />

        {/* TODO: moment show page */}
        <AppRoute
          exact
          path='/moments/:id'
          component={MiceMomentsExpandedView}
          hideBreadcrumb={false}
        />

        {/* TODO: static pages */}
        <AppRoute exact path='/terms' component={Terms} />
        <AppRoute exact path='/data-privacy' component={DataPrivacy} />
        <AppRoute exact path='/imprint' component={Imprint} />
        <AppRoute exact path='/get-listed' component={GetListed} />

        {/* TODO: search results */}
        <AppRoute
          exact
          path='/results'
          component={Results}
          hideHeaderButtons
          hideSearchBar={false}
          hideBreadcrumb={false}
        />

        {/* TODO: search results moments */}
        <AppRoute
          exact
          path='/moments'
          component={Results}
          filterMode='moments'
          hideHeaderButtons
          hideBreadcrumb={false}
          hideSearchBar={false}
        />

        {/* TODO: cart implementation */}
        <AppRoute exact path='/cart' component={Cart} />

        {/* TODO: suppliers show actions */}
        <AppRoute
          exact
          path='/suppliers/:objectID'
          component={SupplierRedirect}
        />

        {/* TODO: redirect to show action */}
        <Route
          exact
          path='/supplier-home/:objectID'
          render={(props) => (
            <Redirect
              to={{
                pathname: `/hotel/${props.match.params.objectID}`,
                search: props.location.search
              }}
            />
          )}
        />

        {/* TODO redirect e.g.: /hotel/1234 or /location/1234 */}
        {deliverables.map((deliverable, i) => {
          return (
            <AppRoute
              key={i}
              exact
              path={`/${deliverable}/:objectID`}
              view={`${deliverable}`}
              component={SupplierHome}
              hideBreadcrumb={false}
            />
          )
        })}

        {/* TODO start page */}
        <AppRoute exact path='/' component={StartPage} />
        {/* catch all and redirect to start page */}
        <Route render={props => <Redirect to='/' {...props} />} />
      </Switch>
    </Suspense>
  )
}

export default Routes
